import { NgModule } from '@angular/core';

import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  imports: [ MatTableModule, MatToolbarModule, MatButtonModule, MatSidenavModule, MatDividerModule, MatListModule, MatIconModule],
  exports: [ MatTableModule, MatToolbarModule, MatButtonModule, MatSidenavModule, MatDividerModule, MatListModule, MatIconModule]
})
export class MaterialModule {

}
