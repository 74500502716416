import { Component } from '@angular/core';

import { onMainContentChange } from 'common/animations/sideNav.animation';
import { Page } from 'common/model/navigation/navPage.model';
import { AuthService } from 'common/services/auth.service';
import { SideNavService } from 'common/services/navigation/sideNav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [ onMainContentChange ]
})
export class AppComponent {

  public onSideNavChange: boolean;
  public pageList: Page[];

  constructor(private _sidenavService: SideNavService, public auth : AuthService) {
    this._sidenavService.sideNavState$.subscribe( res => {
      this.onSideNavChange = res;
    });

    this.pageList = [
      {name: 'Home', link:'/', icon: 'home'},
      {name: 'User List', link:'/users', icon: 'manage_accounts'},
    ]
  }
}
