import { Component, OnInit } from '@angular/core';
import { AuthService } from 'common/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {


  constructor(public auth: AuthService) {  }

  ngOnInit() {
  }


  googleSignin() {
    this.auth.googleSignin();
  }

  async logout() {
    await this.auth.signOut();
  }
}
