<div>
  <app-header></app-header>

  <span *ngIf="auth.user$ | async; then authenticated else guest">
    loading...
  </span>

    <!-- User NOT logged in -->
  <ng-template #guest>
      <div class="main_content">
        <div style="padding: 20px">
          <router-outlet></router-outlet>
        </div>
      </div>
  </ng-template>

    <!-- User logged in -->
  <ng-template #authenticated>

    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav #leftSidenav mode="side" opened>
        <app-left-menu [pages]="pageList"></app-left-menu>
      </mat-sidenav>
      <mat-sidenav-content [@onMainContentChange]="onSideNavChange ? 'open': 'close'">
        <div class="main_content">
          <div style="padding: 20px">
            <router-outlet></router-outlet>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>

  </ng-template>

</div>
