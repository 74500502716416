import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { User } from 'common/model/user.model';
import { UserDataService } from 'common/services/data/user.data.service';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  displayedColumns: string[] = ['id', 'name', 'email', 'phone', 'other'];
  dataSource: Observable<User[]>;

  constructor(userDS: UserDataService) {
    this.dataSource = userDS.list$();
  }

  ngOnInit(): void {
  }

}
